/**
 * type Node = {
 *  id: string,
 *  title: string,
 *  isSelected: boolean,
 *  childrens: Array<Node>
 * }
 *
 * @param {Node} node // : Node
 * @param {string} id // : Node.id
 * @param {boolean} isSelected  // : boolean : Node.isSelected
 * @returns {Node} // : Node
 *
 *
 *  **isSelected applicable only for leaf nodes & for other nodes you can use it but there is no use of this field
 */
export const updateDataNode = (node, id, isSelected) => {
  const tNode = JSON.parse(JSON.stringify(node));

  if (tNode.id === id) {
    tNode.isSelected = isSelected;
    return tNode;
  }

  if (tNode?.childrens) {
    for (let i = 0; i < tNode.childrens.length; i++) {
      tNode.childrens[i] = updateDataNode(
        tNode.childrens.at(i),
        id,
        isSelected
      );
    }
  }

  return tNode;
};

/**
 * type Node = {
 *  id: string,
 *  title: string,
 *  isSelected: boolean,
 *  childrens: Array<Node>
 * }
 *
 * @param {Node} node // : Node
 * @returns [string] // : Node
 *
 *
 *  **isSelected applicable only for leaf nodes & for other nodes you can use it but there is no use of this field
 */
export const getSelectedNodeIds = (node) => {
  const paths = [];
  const helper = (node, path) => {
    if (!node?.childrens) {
      if (node.isSelected) {
        paths.push(path.substring(0, path.length - 1));
      }
    } else {
      for (const childNode of node.childrens) {
        helper(childNode, path + childNode.id + "/");
      }
    }
  };
  helper(node, "");
  return paths;
};

/**
 * type Node = {
 *  id: string,
 *  title: string,
 *  isSelected: boolean,
 *  childrens: Array<Node>
 * }
 *
 * @param {Node} node // : Node
 * @returns {boolean}
 *
 *
 *  **isSelected applicable only for leaf nodes & for other nodes you can use it but there is no use of this field
 */
export const checkIfAnyChildIsSelectedOrNot = (node) => {
  const helper = (node) => {
    if (!node?.childrens) {
      if (node.isSelected) {
        return true;
      }
    } else {
      for (const childNode of node.childrens) {
        if (helper(childNode)) {
          return true;
        }
      }
    }
    return false;
  };
  return helper(node);
};

/**
 * type Node = {
 *  id: string,
 *  title: string,
 *  isSelected: boolean,
 *  childrens: Array<Node>
 * }
 *
 * @param {Node} node // : Node
 * @returns {Node} // : Node
 *
 *
 *  **isSelected applicable only for leaf nodes & for other nodes you can use it but there is no use of this field
 */
export const resetNodeData = (node) => {
  const helper = (node) => {
    const tNode = JSON.parse(JSON.stringify(node));
    if (!tNode?.childrens) {
      tNode.isSelected = false;
    } else {
      for (let i = 0; i < tNode.childrens.length; i++) {
        tNode.childrens[i] = helper(tNode.childrens[i]);
      }
    }
    return tNode;
  };
  return helper(node);
};

// export class Tree {
//   #root;
//   constructor(data) {
//     if (data) {
//       this.#root = JSON.parse(JSON.stringify(data));
//     }
//   }

//   getRoot() {
//     return this.#root;
//   }

//   #updateNodeData(node, id, isSelected) {
//     const tNode = JSON.parse(JSON.stringify(node));

//     if (tNode.id === id) {
//       tNode.isSelected = isSelected;
//       return tNode;
//     }

//     if (tNode?.childrens) {
//       for (let i = 0; i < tNode.childrens.length; i++) {
//         tNode.childrens[i] = updateDataNode(
//           tNode.childrens.at(i),
//           id,
//           isSelected
//         );
//       }
//     }

//     return tNode;
//   }

//   setSelectValueByNodeId(id, isSelected) {
//     this.#root = this.#updateNodeData(this.#root, id, isSelected);
//     return this;
//   }
// }

// const r = new Tree();
