import React from "react";
class ErrorBoundaries extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  async componentDidCatch(error, info) {
    const errorReloadCount = sessionStorage.getItem("_ERROR_COUNT");
    if (!errorReloadCount || parseInt(errorReloadCount) <= 0) {
      sessionStorage.setItem(
        "_ERROR_COUNT",
        errorReloadCount ? parseInt(errorReloadCount) + 1 : 1
      );
      window.location.reload();
    } else {
      sessionStorage.setItem(
        "_ERROR_COUNT",
        errorReloadCount ? parseInt(errorReloadCount) + 1 : 1
      );
    }

    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundaries;
