import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FaAngleLeft } from "react-icons/fa";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

export default function DateRangeSelector1({ parentStates, setParentStates }) {
  return (
    <AnimatePresence>
      {parentStates.DateRangeSelector1?.isRender && (
        <Modal
          parentStates={parentStates}
          callBack={parentStates.DateRangeSelector1.callBack}
          selectors={parentStates.DateRangeSelector1.selectors}
          setParentStates={setParentStates}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ setParentStates, callBack, selectors }) => {
  // selectors : ['month']
  const handleClose = () => {
    setParentStates((p) => ({
      ...p,
      DateRangeSelector1: {
        isRender: false,
        type: "",
        callBack: () => {},
      },
    }));
  };

  const [selectedYear, setSelectedYear] = useState(moment().year());

  const [states, setStates] = useState({
    from: moment().startOf("day").toISOString(),
    to: moment().endOf("day").toISOString(),
    label: "Today",
    isCustom: false,
    selectedNav: selectors?.length > 0 ? selectors[0] : "past",
  });

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const onDone = (dateOption) => {
    if (dateOption) {
      callBack({
        from: moment(dateOption.from).year(selectedYear).toDate(),
        to: moment(dateOption.to).year(selectedYear).toDate(),
        label:
          dateOption.label +
          (selectedYear !== moment().year() || dateOption?.type === "month"
            ? ` ${selectedYear}`
            : ""),
      });
    } else {
      callBack({
        from: states.from,
        to: states.to,
        label:
          moment(states.from).year(selectedYear).format("Do, MMM YYYY") +
          " to " +
          moment(states.to).year(selectedYear).format("Do, MMM YYYY"),
      });
    }
    handleClose();
  };

  // Generate an array of years from 2021 to two years from now using Moment.js
  const generateYearOptions = () => {
    const startYear = 2021;
    const endYear = moment().year() + 2; // Two years from now
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const monthWiseDateOptions = [];

  for (let i = 0; i < 12; i++) {
    monthWiseDateOptions.push({
      label:
        moment().month(i).format("MMMM") +
        (moment().month() === i ? " (this month)" : "") +
        (moment().month() === i + 1 ? " (last month)" : ""), // Get the full month name
      from: moment().month(i).startOf("month").toISOString(),
      to: moment().month(i).endOf("month").toISOString(),
      type: "month",
    });
  }

  const getDateOptions = () => {
    switch (states.selectedNav) {
      case "past":
        return pastDateOptions;
      case "future":
        return futureDateOptions;
      case "weeks":
        return weekWiseDateOptions;
      case "month":
        return monthWiseDateOptions;
      default:
        return [];
    }
  };

  const navigationData = [
    {
      label: "Past",
      code: "past",
    },
    {
      label: "Months",
      code: "month",
    },
    {
      label: "Future",
      code: "future",
    },
    {
      label: "Weeks",
      code: "weeks",
    },
    {
      label: "Custom",
      code: "custom",
    },
  ];

  return (
    <div className="fixed right-0 top-0 left-0 bottom-0 flex justify-end z-[999]">
      <div
        onClick={handleClose}
        className="fixed right-0 top-0 left-0 bottom-0 z-[1] bg-[#00000092]"
      ></div>
      <motion.div
        initial={{ translateX: 500, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{ translateX: 500, opacity: 0 }}
        transition={{ duration: 0.2, ease: "linear" }}
        className={`w-full ${
          states.isCustom ? " max-w-[60rem]" : " max-w-[30rem]"
        }   h-full text-[.875rem] relative z-[2] flex flex-col items-center  bg-[white]  `}
      >
        <div className="flex w-full gap-2 items-center bg-[white] p-4 pb-2 pt-3 text-[1.125rem] font-[500] ">
          <FaAngleLeft onClick={handleClose} className="text-[1.25rem]" />
          <div>Select Date range</div>
        </div>
        <div className=" w-full flex mt-2">
          {navigationData.map((curr) => {
            if (
              selectors &&
              selectors.length > 0 &&
              !selectors.includes(curr.code)
            ) {
              return null;
            }
            return (
              <div
                className={`flex-1 text-center py-2 cursor-pointer border-b-2 ${
                  states.selectedNav === curr.code
                    ? " blue-gradient border-[#2136d4]"
                    : " border-gray-200 bg-gray-50 text-[gray]"
                } font-[500]`}
                onClick={() => {
                  if (curr.code === "custom") {
                    setStates((p) => ({
                      ...p,
                      isCustom: true,
                      selectedNav: curr.code,
                    }));
                  } else {
                    setStates((p) => ({
                      ...p,
                      selectedNav: curr.code,
                      isCustom: false,
                    }));
                  }
                }}
              >
                {curr.label}
              </div>
            );
          })}
        </div>
        {states.isCustom ? (
          <div className="flex flex-col w-full items-center justify-center mt-4 px-4 ">
            <div className="w-full flex justify-between my-4">
              <div className=" flex  whitespace-nowrap flex-col  gap-1  ">
                <div className=" text-[.875rem] ">From</div>
                <input
                  type="date"
                  className="border rounded-md border-gray-200  p-2 px-4 "
                  value={moment(states.from).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setStates((p) => ({
                      ...p,
                      from: moment(e.target.value, "YYYY-MM-DD").toISOString(),
                    }));
                  }}
                />
              </div>
              <div className=" flex whitespace-nowrap  flex-col gap-1  ">
                <div className=" text-[.875rem] ">To</div>
                <input
                  type="date"
                  className="border rounded-md border-gray-200  p-2 px-4 "
                  value={moment(states.to).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setStates((p) => ({
                      ...p,
                      to: moment(e.target.value, "YYYY-MM-DD").toISOString(),
                    }));
                  }}
                />
              </div>
            </div>

            <div className="rounded-lg border  border-gray-200 overflow-scroll bg-blue-200 flex w-full p-4">
              <DateRangePicker
                onChange={(item) => {
                  const { startDate, endDate } = item.selection;
                  const startDate_ = moment(startDate)
                    .startOf("day")
                    .toISOString();
                  const endDate_ = moment(endDate).endOf("day").toISOString();
                  setState([item.selection]);
                  setStates((p) => ({
                    ...p,
                    from: startDate_,
                    to: endDate_,
                  }));
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                // have 2 months on large devices and 1 month on small devices
                months={2}
                ranges={state}
                direction="horizontal"
              />
            </div>

            <div
              onClick={() => onDone()}
              className=" rounded-lg p-2    w-full mt-4 text-[1rem]  text-center blue-gradient "
            >
              Done
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-full overflow-scroll pb-[3rem] w-full mt-4 ">
            <div className="flex items-center justify-end gap-2 px-4 bg-gradient-to-t from-blue-200 to-white border-b border-[#2136d4] py-2">
              <div>Year: </div>
              {/* Year Selector */}
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                className="border p-2 rounded-md w-[10rem]"
              >
                {generateYearOptions().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            {getDateOptions().map((curr, idx) => {
              return (
                <div
                  onClick={() => {
                    setStates((p) => ({ ...p, ...curr }));
                    onDone(curr);
                  }}
                  key={idx}
                  className="w-full border-b border-gray p-4 hover:bg-gray-50 cursor-pointer"
                >
                  {curr.label}
                </div>
              );
            })}
          </div>
        )}
      </motion.div>
    </div>
  );
};

const pastDateOptions = [
  {
    from: moment().startOf("day").toISOString(),
    to: moment().endOf("day").toISOString(),
    label: "Today",
  },
  {
    from: moment().subtract(1, "days").startOf("day").toISOString(),
    to: moment().subtract(1, "days").endOf("day").toISOString(),
    label: "Yesterday",
  },
  {
    from: moment().subtract(3, "days").startOf("day").toISOString(),
    to: moment().subtract(1, "days").endOf("day").toISOString(),
    label: "Last 3 days",
  },
  {
    from: moment().subtract(7, "days").startOf("day").toISOString(),
    to: moment().endOf("day").toISOString(),
    label: "Last 7 Days (Today included)",
  },
  {
    from: moment().subtract(14, "days").startOf("day").toISOString(),
    to: moment().endOf("day").toISOString(),
    label: "Last 14 Days (Today included)",
  },
  {
    from: moment().startOf("isoWeek").toISOString(),
    to: moment().endOf("isoWeek").toISOString(),
    label: "This week",
  },
  {
    from: moment().subtract(1, "weeks").startOf("isoWeek").toISOString(),
    to: moment().subtract(1, "weeks").endOf("isoWeek").toISOString(),
    label: "Last week",
  },
  {
    from: moment().startOf("month").toISOString(),
    to: moment().endOf("month").toISOString(),
    label: "This month",
  },
  {
    from: moment().subtract(1, "months").startOf("month").toISOString(),
    to: moment().subtract(1, "months").endOf("month").toISOString(),
    label: "Last month",
  },
  {
    from: moment().subtract(2, "months").startOf("month").toISOString(),
    to: moment().subtract(1, "months").endOf("month").toISOString(),
    label: "Last 2 months",
  },
  {
    from: moment().subtract(3, "months").startOf("month").toISOString(),
    to: moment().subtract(1, "months").endOf("month").toISOString(),
    label: "Last 3 months",
  },
  {
    from: moment().subtract(6, "months").startOf("month").toISOString(),
    to: moment().subtract(1, "months").endOf("month").toISOString(),
    label: "Last 6 months",
  },
  {
    from: moment("01-01-2022", "DD-MM-YYYY").startOf("year").toISOString(),
    to: moment().add(3, "years").endOf("year").toISOString(),
    label: "All time",
  },
  {
    from: moment().startOf("years").toISOString(),
    to: moment().endOf("years").toISOString(),
    label: "This Year",
  },
  {
    from: moment().subtract(1, "years").startOf("year").toISOString(),
    to: moment().subtract(1, "years").endOf("year").toISOString(),
    label: "Last Year",
  },
  {
    from: moment().subtract(2, "years").startOf("year").toISOString(),
    to: moment().subtract(1, "years").endOf("year").toISOString(),
    label: "Last 2 Year",
  },
];

const futureDateOptions = [
  {
    from: moment().add(1, "days").startOf("day").toISOString(),
    to: moment().add(1, "days").endOf("day").toISOString(),
    label: "Tomorrow",
  },
  {
    from: moment().add(1, "weeks").startOf("isoWeek").toISOString(),
    to: moment().add(1, "weeks").endOf("isoWeek").toISOString(),
    label: "Next week",
  },
  {
    from: moment().add(1, "months").startOf("month").toISOString(),
    to: moment().add(1, "months").endOf("month").toISOString(),
    label: "Next month",
  },
  {
    from: moment().add(1, "days").startOf("day").toISOString(),
    to: moment().add(3, "days").endOf("day").toISOString(),
    label: "Next 3 days",
  },
  {
    from: moment().add(1, "weeks").startOf("isoWeek").toISOString(),
    to: moment().add(3, "weeks").endOf("isoWeek").toISOString(),
    label: "Next 3 weeks",
  },
  {
    from: moment().add(1, "weeks").startOf("isoWeek").toISOString(),
    to: moment().add(12, "weeks").endOf("isoWeek").toISOString(),
    label: "Next 12 weeks",
  },
  {
    from: moment().add(1, "months").startOf("month").toISOString(),
    to: moment().add(2, "months").endOf("month").toISOString(),
    label: "Next 2 months",
  },
  {
    from: moment().add(6, "months").startOf("month").toISOString(),
    to: moment().add(6, "months").endOf("month").toISOString(),
    label: "Next 6 months",
  },
];

const generateWeekOptions = () => {
  const currentWeek = moment().isoWeek();
  const currentYear = moment().year();
  const weekOptions = [];

  for (let i = -5; i <= 5; i++) {
    const weekNumber = currentWeek + i;
    const startOfWeek = moment()
      .year(currentYear)
      .isoWeek(weekNumber)
      .startOf("isoWeek");
    const endOfWeek = moment()
      .year(currentYear)
      .isoWeek(weekNumber)
      .endOf("isoWeek");

    weekOptions.push({
      from: startOfWeek.toISOString(),
      to: endOfWeek.toISOString(),
      label: `Week ${weekNumber} (${startOfWeek.format(
        "DD MMM"
      )} - ${endOfWeek.format("DD MMM YY")})`,
    });
  }

  return weekOptions;
};

const weekWiseDateOptions = [
  {
    from: moment().startOf("isoWeek").toISOString(),
    to: moment().endOf("isoWeek").toISOString(),
    label:
      "This week  (" +
      moment().startOf("isoWeek").format("DD MMM, ddd") +
      " - " +
      moment().endOf("isoWeek").format("DD MMM, ddd YY") +
      ")" +
      " week " +
      moment().isoWeek(),
  },
  {
    from: moment().add(1, "weeks").startOf("isoWeek").toISOString(),
    to: moment().add(1, "weeks").endOf("isoWeek").toISOString(),
    label:
      "Next week (" +
      moment().add(1, "weeks").startOf("isoWeek").format("DD MMM") +
      " - " +
      moment().add(1, "weeks").endOf("isoWeek").format("DD MMM YY") +
      ")",
  },
  {
    from: moment().subtract(1, "weeks").startOf("isoWeek").toISOString(),
    to: moment().subtract(1, "weeks").endOf("isoWeek").toISOString(),
    label: `Last week (${moment()
      .subtract(1, "weeks")
      .startOf("isoWeek")
      .format("DD MMM")} - ${moment()
      .subtract(1, "weeks")
      .endOf("isoWeek")
      .format("DD MMM YY")})`,
  },

  ...generateWeekOptions(),
];
