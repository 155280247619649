import React, { useEffect, useState } from "react";
import { v2Api } from "../../../api/v2.api";
import { MdEdit } from "react-icons/md";
import { TbUserShare } from "react-icons/tb";
import { PiUserSwitchFill } from "react-icons/pi"; // Import the switch icon
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom"; // Import Link from react-router-dom
import { get_admins_by_query_obj } from "../../../api/adminsApi";
import { FaRegCalendar } from "react-icons/fa";

export default function EnquiryList() {
  const [queries, setQueries] = useSearchParams();

  let filter = queries.get("filter");
  filter = filter ? JSON.parse(filter) : null;

  //   filter: {
  //     enquiryStatus: "all";
  //     dateRange: {
  //       dateFrom: "2022-01-01T00:00:00.000Z";
  //       dateTo: "2022-01-01T23:59:59.999Z";
  //       dateLabel: "Today";
  //     };
  //   }

  const getDefaultDateRange = () => {
    const today = moment().startOf("day");
    return {
      dateFrom: today.toISOString(),
      dateTo: today.endOf("day").toISOString(),
      dateLabel: "Today",
    };
  };

  console.log(filter);

  const [states, setStates] = useState({
    enquiries: [],
    isLoading: false,
    agentChanging: false,
    selectedStatusFilter: filter?.enquiryStatus || "all", // Initialize selected filter to "all"
    selectedAgent: "all", // Initialize selected agent to "all"
    agents: [], // Initialize agents array
    dateRange: filter?.dateRange || getDefaultDateRange(),
    dateRangeApplingField: "createdAt",
  });

  useEffect(() => {
    fetchAgents(); // Fetch agents on component mount
    onInitialLoad();
    // eslint-disable-next-line
  }, [
    states.selectedStatusFilter,
    states.selectedAgent,
    states.dateRange,
    states.dateRangeApplingField,
  ]);

  // Fetch agents from API
  const fetchAgents = async () => {
    try {
      const response = await get_admins_by_query_obj({});
      if (response.isSuccess) {
        setStates((prevState) => ({
          ...prevState,
          agents: response.admins,
        }));
      } else {
        console.error("Failed to fetch agents:", response.message);
      }
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  const onInitialLoad = async () => {
    try {
      setStates((p) => ({ ...p, isLoading: true }));
      const res = await v2Api({
        operation: "get",
        tags: ["enquiry"],
        enquiryData: {
          enquiryStatus:
            states.selectedStatusFilter !== "all"
              ? states.selectedStatusFilter
              : undefined,
          agent:
            states.selectedAgent !== "all" ? states.selectedAgent : undefined, // Filter by selected agent

          [states.dateRangeApplingField]: {
            $gte: moment(states.dateRange?.dateFrom || "")
              .startOf("day")
              .toDate(),
            $lte: moment(states.dateRange?.dateTo || "")
              .endOf("day")
              .toDate(),
          },
        },
        sorting: {
          [states.dateRangeApplingField]: "desc",
        },
      });
      if (res.isSuccess) {
        setStates((p) => ({
          ...p,
          isLoading: false,
          enquiries: res.data,
        }));
      } else {
        setStates((p) => ({
          ...p,
          isLoading: false,
        }));
      }
    } catch (error) {
      console.log(error);
      setStates((p) => ({
        ...p,
        isLoading: false,
      }));
    }
  };

  const formatDate = (date) => {
    return date ? moment(date).format("Do MMM YYYY, hA") : "-";
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "open":
        return "bg-green-200 text-green-800";
      case "dropped":
        return "bg-red-200 text-red-800";
      case "scheduled":
        return "bg-yellow-200 text-yellow-800";
      case "converted":
        return "bg-blue-200 text-blue-800";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };

  const initiateEditProcess = (enquiry) => {
    window.handleEnquery({
      editData: enquiry,
      isEdit: true,
      callBackFun: () => {
        onInitialLoad();
      },
    });
  };

  const handleStatusChange = async (enquiryId, newStatus) => {
    const handleSubmit = async (enquiryStatus, remark) => {
      try {
        setStates((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        const option = {
          tags: ["enquiry"],
          operation: "update",
          enquiryId,
          enquiryData: {
            enquiryStatus,
            remark,
          },
        };

        const res = await v2Api(option);
        if (res.isSuccess) {
          window.toast("Enquiry status updated");
          onInitialLoad();
        } else {
          window.toast(res.message, "error");
        }

        setStates((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      } catch (error) {
        console.error(error);
        setStates((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    };

    const handleStatusConfirmation = () => {
      if (newStatus === "dropped") {
        window.ask(
          "Reason for dropping",
          "Please provide a reason for dropping the enquiry",
          ["Reason"],
          "Submit",
          (r) => {
            handleSubmit("dropped", r[0]);
          }
        );
      } else if (newStatus === "scheduled") {
        window.warningModal({
          msg: "Have you scheduled the enquiry?",
          rb_text: "Yes",
          lb_text: "No",
          rb_fn: () => {
            handleSubmit("scheduled");
          },
          lb_fn: () => {
            window.toast("Please schedule the enquiry first");
          },
        });
      } else {
        handleSubmit(newStatus);
      }
    };

    window.warningModal({
      msg: "Are you sure you want to change the status?",
      rb_text: "Yes",
      lb_text: "No",
      rb_fn: handleStatusConfirmation,
      lb_fn: () => {
        // Do nothing on cancel
      },
    });
  };

  const handleSwitchAgent = async (enquiry) => {
    try {
      setStates((prevState) => ({
        ...prevState,
        agentChanging: true,
      }));
      const allAdmins = await get_admins_by_query_obj({});
      if (allAdmins.isSuccess && allAdmins.admins.length > 0) {
        console.log(allAdmins.admins);
        window.askRadio(
          "Select agent",
          "",
          allAdmins.admins.map((curr) => {
            return curr.userName + " - " + curr._id;
          }),
          "Submit",
          async (r) => {
            setStates((prevState) => ({
              ...prevState,
              agentChanging: true,
            }));
            const option = {
              tags: ["enquiry"],
              operation: "update",
              enquiryId: enquiry._id,
              enquiryData: {
                agent: r.split(" - ")[1],
              },
            };
            const res = await v2Api(option);
            if (res.isSuccess) {
              window.toast("Agent updated");
              onInitialLoad();
              setStates((prevState) => ({
                ...prevState,
                agentChanging: false,
              }));
            } else {
              window.toast(res.message, "error");
              setStates((prevState) => ({
                ...prevState,
                agentChanging: false,
              }));
            }
          },
          false
        );
      } else {
        window.toast(allAdmins.message, "error");
      }
      setStates((prevState) => ({
        ...prevState,
        agentChanging: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterChange = (selectedStatusFilter) => {
    setStates((prevState) => ({
      ...prevState,
      selectedStatusFilter,
    }));
  };

  const handleAgentFilterChange = (selectedAgent) => {
    setStates((prevState) => ({
      ...prevState,
      selectedAgent,
    }));
  };

  return (
    <div className="pt-[5rem] p-4 flex flex-col bg-blue-50 h-full">
      <div className="w-full flex justify-between md:flex-row flex-col">
        <div className="flex gap-2 flex-wrap md:gap-4 mb-4 text-[.75rem] md:text-[.875rem] ">
          {["all", "open", "dropped", "scheduled", "converted"].map(
            (status) => (
              <button
                key={status}
                onClick={() => handleFilterChange(status)}
                className={`py-2 px-4 rounded-lg ${
                  states.selectedStatusFilter === status
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-800"
                }`}
              >
                {status}
              </button>
            )
          )}
        </div>
        <div className="mb-4 text-[.75rem] flex gap-2 flex-wrap md:text-[.875rem]">
          <select
            value={states.dateRangeApplingField}
            onChange={(e) => {
              setStates((p) => ({
                ...p,
                dateRangeApplingField: e.target.value,
              }));
            }}
            className="py-2 px-4 rounded-lg bg-white border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500"
          >
            <option value={"createdAt"}>Date range on Created at</option>
            <option value={"updatedAt"}>Date range on Updated at</option>
            <option value={"scehduledDate"}>Date range on Scehduled at</option>
            <option value={"celebrationDate"}>
              Date range on Celebration at
            </option>
          </select>
          <span
            onClick={() => {
              window.setDateRange({
                type: "userAnalyticsCardDateRange",
                callBack: (r) => {
                  setStates((p) => ({
                    ...p,
                    dateRange: {
                      dateFrom: r.from,
                      dateTo: r.to,
                      dateLabel: r.label,
                    },
                  }));
                },
              });
            }}
            className=" border cursor-pointer shadow-md bg-white rounded-xl flex gap-2 items-center text-[.825rem] px-2 py-1 "
          >
            {states.dateRange?.dateLabel} <FaRegCalendar />
          </span>

          <select
            value={states.selectedAgent}
            onChange={(e) => handleAgentFilterChange(e.target.value)}
            className="py-2 px-4 rounded-lg bg-white border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500"
          >
            <option value="all">All Agents</option>
            {states.agents.map((agent) => (
              <option key={agent._id} value={agent._id}>
                {agent.userName}
              </option>
            ))}
          </select>
        </div>
      </div>
      {states.isLoading ? (
        <div className="text-center text-gray-500 flex items-center w-full justify-center pt-[5rem] gap-2">
          <div className="loader w-[1rem]"></div> Loading...
        </div>
      ) : (
        <div className="overflow-auto h-[100vh] pb-[40vh]">
          <table className="min-w-full  bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-blue-600 text-white whitespace-nowrap text-[.8125rem] font-medium">
              <tr>
                {[
                  "#",
                  "Edit",
                  "Agent",
                  "User",
                  "Phone Number",
                  "Status",
                  "Area",
                  "Occasion Name",
                  "Celebration Date",
                  "Budget (₹)",
                  "Traffic Source",
                  "Scheduled Date",
                ].map((header) => (
                  <th key={header} className="py-3 px-5 text-left font-[500]">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {states.enquiries.map((enquiry, index) => (
                <tr
                  key={enquiry?._id}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } whitespace-nowrap text-[.8125rem]`}
                >
                  <td className="py-3 px-5 border-b text-center">
                    {index + 1}
                  </td>
                  <td className="py-3 px-5 border-b text-center">
                    <MdEdit
                      className="text-gray-600 cursor-pointer"
                      onClick={() => initiateEditProcess(enquiry)}
                    />
                  </td>
                  <td className="py-3 px-5 border-b ">
                    <div className=" flex items-center">
                      {enquiry?.agent?.userName || "-"}
                      <PiUserSwitchFill
                        className="ml-2 text-gray-600 cursor-pointer"
                        onClick={() => handleSwitchAgent(enquiry)}
                      />
                    </div>
                  </td>
                  <td className="py-3 px-5 border-b ">
                    <Link
                      to={`/user/profile/${enquiry?.user?._id}`}
                      className="flex items-center  "
                    >
                      {enquiry?.name}
                      <TbUserShare className="ml-2  text-gray-600" />
                    </Link>
                  </td>
                  <td className="py-3 px-5 border-b">
                    <Link to={`/user/profile/${enquiry?.user?._id}`}>
                      {enquiry?.phoneNumber}
                    </Link>
                  </td>
                  <td className="py-3 px-5 border-b">
                    <select
                      value={enquiry?.enquiryStatus}
                      className={`py-2 px-4 rounded ${getStatusColor(
                        enquiry?.enquiryStatus
                      )}`}
                      onChange={(e) => {
                        handleStatusChange(enquiry._id, e.target.value);
                      }}
                    >
                      <option value="open">Open</option>
                      <option value="dropped">Dropped</option>
                      <option value="scheduled">Scheduled</option>
                      <option value="converted">Converted</option>
                    </select>
                  </td>
                  <td className="py-3 px-5 border-b">{enquiry?.area}</td>
                  <td className="py-3 px-5 border-b">
                    {enquiry?.occasionName}
                  </td>
                  <td className="py-3 px-5 border-b">
                    {formatDate(enquiry?.celebrationDate)}
                  </td>
                  <td className="py-3 px-5 border-b">
                    {`${enquiry?.budget?.minPrice || ""} - ${
                      enquiry?.budget?.maxPrice || ""
                    }`}
                  </td>
                  <td className="py-3 px-5 border-b">
                    {enquiry?.trafficSource}
                  </td>
                  <td className="py-3 px-5 border-b">
                    {formatDate(enquiry?.scheduledDate)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
