import { memo } from "react";

export const PrimaryBtn = memo(
  ({ id, title, isActive, handleOnClick = () => {}, className }) => {
    return (
      <div
        key={id}
        onClick={handleOnClick}
        className={`flex items-center justify-center py-1 px-4 w-full text-xs rounded-md cursor-pointer capitalize text-nowrap
         border hover:ease-in-out hover:duration-[300ms] ${
           isActive
             ? "bg-[#2136D4] text-white border-[#2136D4]"
             : "text-gray-600 border-gray-200 hover:border-blue-400 hover:text-[#2136d4] hover:bg-blue-50"
         } ${className}`}
      >
        {title}
      </div>
    );
  }
);
