import React, { useContext, lazy, Suspense } from "react";

// -------------- ** CONTEXTS ** ----------------
import ModalContext from "../context/ModalContext";
import WarningModalv2 from "../assets/WarningModalv2";
import { AnimatePresence } from "framer-motion";
import Toast1 from "../assets/toastModals/Toast1";
import CustomPopup from "../components/modals/popUpComp/CustomPopup";
import DecorOfferDiscountSetModal from "../assets/DecorOfferDiscountSetModal";
import DateRangeSelector1 from "../assets/dateRangeSelectors/DateRangeSelector1";
import LoadingScreen1 from "../assets/loadings/LoadingScreen1";
import AskForInputs1 from "../assets/askModals/AskForInputs1";
import WeekSelector from "../assets/dateRangeSelectors/WeekSelector";
import AskForRadio from "../assets/askModals/AskForRadio";
import HandleEnquiery from "../components/enquiery/subC/HandleEnquiery";
import CallingModal from "../components/calls/subC/CallingModal";
import ViewChartModal from "../assets/ViewChartModal";
import SelectAreaFilterModal from "../assets/SelectAreaFilterModal";
import FloatingCustomModal from "../assets/FloatingCustomModal";
import UpdateEnquiryDetailsModal from "../components/modals/user/UpdateEnquiryDetailsModal";
import ApplyFilterModal from "../assets/ApplyFilterModal";

// -------------------- ** COMPONENTS ** ----------------

const WarningModal = lazy(() =>
  import("../components/modals/warningModal/WarningModal")
);

export default function Modals() {
  // ------------ ** CONTEXTS ** -------------------------
  const { warningModal, states, setStates } = useContext(ModalContext);

  return (
    <div className="pop-up-components">
      {warningModal.isRender && (
        <Suspense fallback={<h1>loading...</h1>}>
          <WarningModal />
        </Suspense>
      )}
      <AskForInputs1 parentStates={states} setParentStates={setStates} />
      <FloatingCustomModal parentStates={states} setParentStates={setStates} />
      <HandleEnquiery parentStates={states} setParentStates={setStates} />
      <AskForRadio parentStates={states} setParentStates={setStates} />
      <CallingModal parentStates={states} setParentStates={setStates} />
      <LoadingScreen1 states={states} setStates={setStates} />
      <DateRangeSelector1 parentStates={states} setParentStates={setStates} />
      <WeekSelector parentStates={states} setParentStates={setStates} />
      <SelectAreaFilterModal
        parentStates={states}
        setParentStates={setStates}
      />
      <DecorOfferDiscountSetModal />

      <WarningModalv2 parentStates={states} setParentStates={setStates} />

      <AnimatePresence>
        {states.customPopup.isRender && <CustomPopup />}
      </AnimatePresence>
      <AnimatePresence>
        {states.toast1.isRender && (
          <Toast1 modalsRenderInfo={states} setModalsRenderInfo={setStates} />
        )}
      </AnimatePresence>

      <ViewChartModal parentStates={states} setParentStates={setStates} />
      <UpdateEnquiryDetailsModal
        parentStates={states}
        setParentStates={setStates}
      />
      <ApplyFilterModal parentStates={states} setParentStates={setStates} />
    </div>
  );
}
