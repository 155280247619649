import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FaAngleLeft } from "react-icons/fa";
import moment from "moment";
import { IoMdInformationCircleOutline } from "react-icons/io";

const WeekSelector = ({ parentStates, setParentStates }) => {
  return (
    <AnimatePresence>
      {parentStates.weekSelector?.isRender && (
        <Modal
          parentStates={parentStates}
          callBack={parentStates.weekSelector.callBack}
          setParentStates={setParentStates}
        />
      )}
    </AnimatePresence>
  );
};

const Modal = ({ setParentStates, callBack }) => {
  const handleClose = () => {
    setParentStates((p) => ({
      ...p,
      weekSelector: {
        isRender: false,
        callBack: () => {},
      },
    }));
  };

  const [states, setStates] = useState({
    from: moment().startOf("isoWeek"),
    to: moment().endOf("isoWeek"),
    isCustom: false,
  });
  const [weeksToShow, setWeeksToShow] = useState([]);

  useEffect(() => {
    const currentWeekStart = moment().startOf("isoWeek");
    const currentWeekEnd = moment().endOf("isoWeek");
    const lastWeekStart = currentWeekStart.clone().subtract(1, "week");
    const lastWeekEnd = currentWeekEnd.clone().subtract(1, "week");
    const previousWeekStart = currentWeekStart.clone().subtract(2, "weeks");
    const previousWeekEnd = currentWeekEnd.clone().subtract(2, "weeks");
    const prev2WeekStart = currentWeekStart.clone().subtract(3, "weeks");
    const prev2WeekEnd = currentWeekEnd.clone().subtract(3, "weeks");
    const nextWeekStart = currentWeekStart.clone().add(1, "week");
    const nextWeekEnd = currentWeekEnd.clone().add(1, "week");

    const currentWeekLabel = "This week";
    const lastWeekLabel = "Last week";
    const nextWeekLabel = "Next week";

    const weeks = [
      {
        from: currentWeekStart,
        to: currentWeekEnd,
        label: currentWeekLabel,
      },
      {
        from: lastWeekStart,
        to: lastWeekEnd,
        label: lastWeekLabel,
      },
      {
        from: nextWeekStart,
        to: nextWeekEnd,
        label: nextWeekLabel,
      },
      {
        from: previousWeekStart,
        to: previousWeekEnd,
        label: "Past week",
      },
      {
        from: prev2WeekStart,
        to: prev2WeekEnd,
        label: "Past week",
      },
    ];
    setWeeksToShow(weeks);
  }, []);

  const selectWeek = (weekStart, weekEnd) => {
    setStates({ from: weekStart, to: weekEnd });
    callBack({
      from: weekStart.startOf("day").toISOString(),
      to: weekEnd.endOf("day").toISOString(),
    });
    handleClose();
  };

  const onDone = () => {
    callBack({
      from: states.from.startOf("day").toISOString(),
      to: states.to.endOf("day").toISOString(),
    });
    handleClose();
  };

  return (
    <div className="fixed right-0 top-0 left-0 bottom-0 flex justify-end z-[999]">
      <div
        onClick={handleClose}
        className="fixed right-0 top-0 left-0 bottom-0 z-[1] bg-[#00000092]"
      ></div>
      <motion.div
        initial={{ translateX: 500, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{ translateX: 500, opacity: 0 }}
        transition={{ duration: 0.2, ease: "linear" }}
        className="w-full max-w-[30rem] h-full text-[.875rem] relative z-[2] flex flex-col items-center bg-white"
      >
        <div className="flex w-full gap-2 items-center bg-white p-4  pt-3 text-[1.125rem] font-[500] ">
          <FaAngleLeft onClick={handleClose} className="text-[1.25rem]" />
          <div>Select Week</div>
        </div>
        {states.isCustom ? (
          <div className="flex flex-col w-full mt-4 ">
            <div
              onClick={() => {
                setStates((p) => ({ ...p, isCustom: false }));
              }}
              className="w-full border-b border-gray flex items-center gap-1 p-4 hover:bg-gray-50 cursor-pointer"
            >
              <FaAngleLeft /> Select from list
            </div>
            <div className="flex  whitespace-nowrap flex-col p-4 gap-4">
              <p className="text-[.75rem] italic flex gap-[6px] items-center">
                {" "}
                <IoMdInformationCircleOutline className="text-[1rem]" /> Select
                any date, week will be determined accordingly{" "}
              </p>
              <div className="flex flex-col">
                <label className="text-[.875rem] mb-1">Start of week</label>
                <input
                  className="border rounded-md border-gray-200  p-2 px-4 "
                  type="date"
                  value={states.from.format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setStates((prev) => ({
                      ...prev,
                      from: moment(e.target.value, "YYYY-MM-DD").startOf(
                        "isoWeek"
                      ),
                      to: moment(e.target.value, "YYYY-MM-DD").endOf("isoWeek"),
                    }))
                  }
                />
              </div>
              <div className="flex flex-col">
                <label className="text-sm mb-1">End of week</label>
                <input
                  type="date"
                  className="border rounded-md border-gray-200  p-2 px-4 "
                  value={states.to.format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setStates((prev) => ({
                      ...prev,
                      from: moment(e.target.value, "YYYY-MM-DD").startOf(
                        "isoWeek"
                      ),
                      to: moment(e.target.value, "YYYY-MM-DD").endOf("isoWeek"),
                    }))
                  }
                />
              </div>
            </div>
            <div
              onClick={() => onDone()}
              className=" rounded-lg p-2 mx-4 w-[5rem] mt-4 text-[1rem]  text-center blue-gradient "
            >
              Done
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-full overflow-scroll pb-[3rem] w-full ">
            <div
              onClick={() => setStates((p) => ({ ...p, isCustom: true }))}
              className="w-full border-b border-gray p-4 hover:bg-gray-50 cursor-pointer"
            >
              Custom
            </div>
            {weeksToShow.map((option, idx) => (
              <div
                key={idx}
                onClick={() => selectWeek(option.from, option.to)}
                className={`w-full border-b border-gray p-4 hover:bg-gray-50 cursor-pointer`}
              >
                {option.label}{" "}
                <span className="text-[.75rem]">
                  {" "}
                  ({option.from.format("Do MMM")} - {option.to.format("Do MMM")}
                  )
                </span>
              </div>
            ))}
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default WeekSelector;
