import React from "react";
import AdminLayoutNavbar from "../../components/navbar/Navbar";
import BottomNav from "../../Layout/BottomNav";
import EnquiryList from "../../components/enquiery/enquiryList/EnquiryList";

export default function EnquiryPage() {
  return (
    <div className=" fixedScreen overflow-hidden ">
      <AdminLayoutNavbar />
      <BottomNav />
      <EnquiryList />
    </div>
  );
}
