import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import AuthMiddleware from "../middlewareComponents/AuthMiddleware";
import EnquiryPage from "../pages/enquiry/EnquiryPage";

// --->  pages
const TableViewIndexPage = lazy(() =>
  import("../pages/tableView/TableViewIndexPage")
);
const TrafficPagev2 = lazy(() => import("../pages/TrafficPagev2"));
const TriggersPage = lazy(() => import("../pages/TriggersPage"));
const URLKeywordManagementPage = lazy(() =>
  import("../pages/seo/URLKeywordManagementPage")
);
const SeoPage = lazy(() => import("../pages/seo/SeoPage"));
const CallLogsPage = lazy(() => import("../pages/CallLogsPage"));
const DecorListingPage = lazy(() =>
  import("../pages/product/DecorListingPage")
);
const ServiceHubPages = lazy(() => import("../pages/hub/ServiceHubPages"));
const InventoryItemProfilePage = lazy(() =>
  import("../pages/inventory/InventoryItemProfilePage")
);
const ProductPagev2 = lazy(() => import("../pages/product/ProductPagev2"));
const DecorProfilePage = lazy(() =>
  import("../pages/product/DecorProfilePage")
);
const CityHubsPage = lazy(() => import("../pages/hub/CityHubsPage"));
const HubsProfilePage = lazy(() => import("../pages/hub/HubsProfilePage"));
const ErrorTrackingPage = lazy(() =>
  import("../pages/errorTracking/ErrorTrackingPage")
);
const TransactionsPage = lazy(() => import("../pages/sales/TransactionsPage"));
const SantaPage = lazy(() => import("../pages/team/SantaPage"));
const SantaProfilePage = lazy(() => import("../pages/team/SantaProfilePage"));
const AffiliatorPage = lazy(() =>
  import("../pages/team/affiliator/AffiliatorPage")
);
const AffiliatorProfilePage = lazy(() =>
  import("../pages/team/affiliator/AffiliatorProfilePage")
);
const AffiliatorsPayoutsList = lazy(() =>
  import(
    "../components/teamComponents/affiliatorComponents/affiliatorPayoutLIst/AffiliatorsPayoutsList"
  )
);
const AdminsPage = lazy(() => import("../pages/admins/AdminsPage"));
const AdminProfilePage = lazy(() => import("../pages/admins/AdminProfilePage"));
const BannersManagementpage = lazy(() =>
  import("../pages/eboUiManagement/BannersManagementpage")
);
const OrderDetailPage = lazy(() => import("../pages/order/OrderDetailPage"));
const UserPage = lazy(() => import("../pages/user/UserPage"));
const UserProfile = lazy(() => import("../pages/user/UserProfile"));
const AbandonedCartPage = lazy(() => import("../pages/user/AbandonedCartPage"));
const LocationPage = lazy(() => import("../pages/location/LocationPage"));
const CreateUserPage = lazy(() => import("../pages/user/CreateUserPage"));
const UserListPage = lazy(() => import("../pages/user/UserListPage"));
const StatisticsPage = lazy(() => import("../pages/StatisticsPage"));
const OrderListingPage = lazy(() => import("../pages/order/OrderListingPage"));
const CustomInvoicePage = lazy(() => import("../pages/CustomInvoicePage"));
const ProductPage = lazy(() => import("../pages/ProductPage"));
const Order = lazy(() => import("../pages/order/Order"));
const LoginPage = lazy(() => import("../pages/LoginPage"));
const Home = lazy(() => import("../pages/Home"));
const HomePage = lazy(() => import("../pages/HomePage"));
const BlogPage = lazy(() => import("../pages/BlogPage"));
const AddonPage = lazy(() => import("../pages/AddonPage"));
const CoupanPage = lazy(() => import("../pages/CoupansPage"));
const SitemapPage = lazy(() => import("../pages/SitemapPage"));
const Checkout = lazy(() => import("../pages/Checkout"));
const FreebiePage = lazy(() => import("../pages/FreebiePage"));
const MaualOrderPlacePage = lazy(() => import("../pages/MaualOrderPlacePage"));
const UpComingCelebrationPage = lazy(() =>
  import("../pages/UpComingCelebrationPage")
);
const SingleProductDetailPage = lazy(() =>
  import("../pages/SingleProductDetailPage")
);
const SingleAddonDetailPage = lazy(() =>
  import("../pages/SingleAddonDetailPage")
);
const TimeSlotPage = lazy(() => import("../pages/TimeSlotPage"));

const TrafficTrackingPage = lazy(() => import("../pages/TrafficTrackingPage"));

const MasterSheetPage = lazy(() =>
  import("../pages/inventory/MasterSheetPage")
);
const MasterSheetItemListPage = lazy(() =>
  import("../pages/inventory/MasterSheetItemListPage")
);
const MasterSheetItemDetailPage = lazy(() =>
  import("../pages/inventory/MasterSheetItemDetailPage")
);
const CitiesListPage = lazy(() => import("../pages/inventory/CitiesListPage"));

const EachInventoryPage = lazy(() =>
  import("../pages/inventory/EachInventoryPage")
);
const DecorCategoriesPage = lazy(() =>
  import("../pages/decorCategory/DecorCategoriesPage")
);
const EboCalendarPage = lazy(() =>
  import("../pages/eboCalendar/EboCalendarPage")
);

const VendorsHomePage = lazy(() => import("../pages/vendors/VendorsHomePage"));

const EboRoutes = () => {
  return (
    <Routes>
      <Route path="/invoice-generator" element={<AuthMiddleware />}>
        <Route
          path="/invoice-generator"
          element={
            <Suspense fallback={<Spinner />}>
              <CustomInvoicePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<AuthMiddleware />}>
        <Route
          path="/"
          element={
            <Suspense fallback={<Spinner />}>
              <Home />
            </Suspense>
          }
        />
      </Route>
      <Route path="/order" element={<AuthMiddleware />}>
        <Route
          path="/order"
          element={
            <Suspense fallback={<Spinner />}>
              <Order />
            </Suspense>
          }
        />
      </Route>
      <Route path="/order/order-list/:status" element={<AuthMiddleware />}>
        <Route
          path="/order/order-list/:status"
          element={
            <Suspense fallback={<Spinner />}>
              <OrderListingPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/order/order-info/:bookingId" element={<AuthMiddleware />}>
        <Route
          path="/order/order-info/:bookingId"
          element={
            <Suspense fallback={<Spinner />}>
              <OrderDetailPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/order/manual-order-place" element={<AuthMiddleware />}>
        <Route
          path="/order/manual-order-place"
          element={
            <Suspense fallback={<Spinner />}>
              <MaualOrderPlacePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/homepage" element={<AuthMiddleware />}>
        <Route
          path="/homepage"
          element={
            <Suspense fallback={<Spinner />}>
              <HomePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/blog" element={<AuthMiddleware />}>
        <Route
          path="/blog"
          element={
            <Suspense fallback={<Spinner />}>
              <BlogPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/traffic" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <TrafficPagev2 />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/signin"
        element={
          <Suspense fallback={<Spinner />}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route path="/Admin/stats" element={<AuthMiddleware />}>
        <Route
          path="/Admin/stats"
          element={
            <Suspense fallback={<Spinner />}>
              <StatisticsPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/Admin/product" element={<AuthMiddleware />}>
        <Route
          path="/Admin/product"
          element={
            <Suspense fallback={<Spinner />}>
              <ProductPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/product-detail" element={<AuthMiddleware />}>
        <Route
          path="/product-detail"
          element={
            <Suspense fallback={<Spinner />}>
              <SingleProductDetailPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/enquiry" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <EnquiryPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/product" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <ProductPagev2 />
            </Suspense>
          }
        />

        <Route
          path="decor/listing"
          element={
            <Suspense fallback={<Spinner />}>
              <DecorListingPage listingType={"decor"} />
            </Suspense>
          }
        />
        <Route
          path="addon/listing"
          element={
            <Suspense fallback={<Spinner />}>
              <DecorListingPage listingType={"addon"} />
            </Suspense>
          }
        />
        <Route
          path="decor/profile/:productId"
          element={
            <Suspense fallback={<Spinner />}>
              <DecorProfilePage profleType={"decor"} />
            </Suspense>
          }
        />
        <Route
          path="addon/profile/:productId"
          element={
            <Suspense fallback={<Spinner />}>
              <DecorProfilePage profleType={"addon"} />
            </Suspense>
          }
        />
      </Route>
      <Route path="/timeslot-detail" element={<AuthMiddleware />}>
        <Route
          path="/timeslot-detail"
          element={
            <Suspense fallback={<Spinner />}>
              <TimeSlotPage />
            </Suspense>
          }
        />
      </Route>
      {/* user related routes */}
      <Route path="/user" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <UserPage />
            </Suspense>
          }
        />
        <Route
          path="create"
          element={
            <Suspense fallback={<Spinner />}>
              <CreateUserPage />
            </Suspense>
          }
        />
        <Route
          path="list"
          element={
            <Suspense fallback={<Spinner />}>
              <UserListPage />
            </Suspense>
          }
        />{" "}
        <Route
          path="abandoned_cart"
          element={
            <Suspense fallback={<Spinner />}>
              <AbandonedCartPage />
            </Suspense>
          }
        />{" "}
        <Route
          path="profile/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <UserProfile />
            </Suspense>
          }
        />
      </Route>
      <Route path="/Admin/Celebration" element={<AuthMiddleware />}>
        <Route
          path="/Admin/Celebration"
          element={
            <Suspense fallback={<Spinner />}>
              <UpComingCelebrationPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/Admin/AddOn" element={<AuthMiddleware />}>
        <Route
          path="/Admin/AddOn"
          element={
            <Suspense fallback={<Spinner />}>
              <AddonPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/addOn-detail" element={<AuthMiddleware />}>
        <Route
          path="/addOn-detail"
          element={
            <Suspense fallback={<Spinner />}>
              <SingleAddonDetailPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/Admin/FreeBie" element={<AuthMiddleware />}>
        <Route
          path="/Admin/FreeBie"
          element={
            <Suspense fallback={<Spinner />}>
              <FreebiePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/Admin/Coupans" element={<AuthMiddleware />}>
        <Route
          path="/Admin/Coupans"
          element={
            <Suspense fallback={<Spinner />}>
              <CoupanPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/Admin/SitemapGenerator" element={<AuthMiddleware />}>
        <Route
          path="/Admin/SitemapGenerator"
          element={
            <Suspense fallback={<Spinner />}>
              <SitemapPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/Admin/Checkout" element={<AuthMiddleware />}>
        <Route
          path="/Admin/Checkout"
          element={
            <Suspense fallback={<Spinner />}>
              <Checkout />
            </Suspense>
          }
        />
      </Route>
      <Route path="/sales/transactions" element={<AuthMiddleware />}>
        <Route
          path="/sales/transactions"
          element={
            <Suspense fallback={<Spinner />}>
              <TransactionsPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/admin/traffic" element={<AuthMiddleware />}>
        <Route
          path="/admin/traffic"
          element={
            <Suspense fallback={<Spinner />}>
              <TrafficTrackingPage />
            </Suspense>
          }
        />
      </Route>
      {/* team */}
      <Route path="/team" element={<AuthMiddleware />}>
        <Route
          path="santa"
          element={
            <Suspense fallback={<Spinner />}>
              <SantaPage />
            </Suspense>
          }
        />{" "}
        <Route
          path="santa/profile/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <SantaProfilePage />
            </Suspense>
          }
        />
        <Route
          path="affiliators"
          element={
            <Suspense fallback={<Spinner />}>
              <AffiliatorPage />
            </Suspense>
          }
        />{" "}
        <Route
          path="affiliators/profile/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AffiliatorProfilePage />
            </Suspense>
          }
        />{" "}
        <Route
          path="affiliators/:id/payout"
          element={
            <Suspense fallback={<Spinner />}>
              <AffiliatorsPayoutsList />
            </Suspense>
          }
        />
        <Route
          path="santa"
          element={
            <Suspense fallback={<Spinner />}>
              <SantaPage />
            </Suspense>
          }
        />{" "}
        <Route
          path="santa/profile/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <SantaProfilePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/admin" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <AdminsPage />
            </Suspense>
          }
        />
        <Route
          path="profile/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AdminProfilePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/hub" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <ServiceHubPages />
            </Suspense>
          }
        />{" "}
        <Route
          path="city/:city"
          element={
            <Suspense fallback={<Spinner />}>
              <CityHubsPage />
            </Suspense>
          }
        />{" "}
        <Route
          path="profile/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <HubsProfilePage />
            </Suspense>
          }
        />
      </Route>
      {/* ebo ui management routes */}
      <Route path="/ebo/banners" element={<AuthMiddleware />}>
        <Route
          path="/ebo/banners"
          element={
            <Suspense fallback={<Spinner />}>
              <BannersManagementpage />
            </Suspense>
          }
        />
        {/* location */}
      </Route>
      <Route path="/location" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <LocationPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/errors" element={<AuthMiddleware />}>
        <Route
          path="/errors"
          element={
            <Suspense fallback={<Spinner />}>
              <ErrorTrackingPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="inventory" element={<AuthMiddleware />}>
        <Route
          path="mastersheet"
          element={
            <Suspense fallback={<Spinner />}>
              <MasterSheetPage />
            </Suspense>
          }
        />
        <Route
          path="itemlist/:category"
          element={
            <Suspense fallback={<Spinner />}>
              <MasterSheetItemListPage />
            </Suspense>
          }
        />
        <Route
          path="mastersheet/item/:itemCode"
          element={
            <Suspense fallback={<Spinner />}>
              <MasterSheetItemDetailPage />
            </Suspense>
          }
        />
        <Route
          path="city"
          element={
            <Suspense fallback={<Spinner />}>
              <CitiesListPage />
            </Suspense>
          }
        />
        <Route
          path="profile/:inventoryCode"
          element={
            <Suspense fallback={<Spinner />}>
              <EachInventoryPage />
            </Suspense>
          }
        />
        <Route
          path="inventoryItemProfile/:inventoryCode/:inventoryItemId"
          element={
            <Suspense fallback={<Spinner />}>
              <InventoryItemProfilePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="vendors" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <VendorsHomePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/decorCategory" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <DecorCategoriesPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/ebo-calendar" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <EboCalendarPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/call-logs" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <CallLogsPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/triggers" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <TriggersPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/seo" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <SeoPage />
            </Suspense>
          }
        />
        <Route
          path="url-keyword-managment"
          element={
            <Suspense fallback={<Spinner />}>
              <URLKeywordManagementPage />
            </Suspense>
          }
        />
      </Route>
      <Route path="/tableView" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<Spinner />}>
              <TableViewIndexPage />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

const Spinner = () => {
  return (
    <div className="flex items-center mx-auto my-auto justify-center">
      <div className="loader w-[2rem] "></div>
    </div>
  );
};

export default EboRoutes;
