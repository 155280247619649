import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross1 } from "react-icons/rx";
export default function WarningModalv2({ parentStates, setParentStates }) {
  const handleClose = async () => {
    parentStates.warningModel.callBackFunctionOnCross &&
      (await parentStates.warningModel.callBackFunctionOnCross());
    setParentStates((p) => ({
      ...p,
      warningModel: {
        msg: "",
        leftBtnText: "",
        rightBtnText: "",
        isRender: false,
        funOnLeftBtn: () => {},
        funOnRightBtn: () => {},
        callBackFunctionOnCross: null,
      },
    }));
  };

  return (
    <AnimatePresence>
      {parentStates.warningModel.isRender && (
        <Modal
          handleClose={handleClose}
          parentStates={parentStates.warningModel}
          type={parentStates.warningModel.type}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ handleClose, parentStates, type }) => {
  if (type === "img") {
    return (
      <div className="fixed top-0 z-[98] flex items-center justify-center right-0 left-0 bottom-0  ">
        <div
          onClick={handleClose}
          className="fixed top-0 z-[-1] flex items-center justify-center right-0 left-0 bottom-0 bg-[#0000003e]"
        ></div>
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.3 }}
          className="  relative   bg-[white]  min-w-[15rem] min-h-[15rem]  rounded-lg   "
        >
          <RxCross1
            onClick={handleClose}
            className=" absolute cursor-pointer top-4 right-4 bg-[white] text-[black] text-[2rem] rounded-full p-[6px]  "
          />
          <img
            alt=""
            src={parentStates.msg}
            className="rounded-lg max-w-[100vh] w-full h-full max-h-[100vh]"
          />
        </motion.div>
      </div>
    );
  }
  if (type === "component") {
    return (
      <div className="fixed top-0 z-[98] flex items-center justify-center right-0 left-0 bottom-0  ">
        <div
          onClick={handleClose}
          className="fixed top-0 z-[-1] flex items-center justify-center right-0 left-0 bottom-0 bg-[#0000003e]"
        ></div>
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.3 }}
          className="  relative flex  items-center justify-center overflow-hidden  bg-[white]   max-w-screen max-h-screen   rounded-lg   "
        >
          {parentStates.msg}
        </motion.div>
      </div>
    );
  }
  return (
    <div className="fixed top-0 z-[98] flex items-center justify-center right-0 left-0 bottom-0  ">
      <div
        onClick={handleClose}
        className="fixed top-0 z-[-1] flex items-center justify-center right-0 left-0 bottom-0 bg-[#0000003e]"
      ></div>
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className="flex items-center justify-center  max-w-[90vw] w-[18rem]  flex-col bg-[white] rounded-lg   "
      >
        <p className=" p-4 text-center text-[.925rem] text-[#171717]">
          {parentStates.msg}
        </p>
        {!(!parentStates.leftBtnText && !parentStates.rightBtnText) && (
          <div className="flex  w-full border-t gap-2">
            {parentStates.leftBtnText && (
              <button
                onClick={() => {
                  if (parentStates.funOnLeftBtn) {
                    parentStates.funOnLeftBtn();
                  }
                  handleClose();
                }}
                className="text-[1rem] border-r cursor-pointer font-[500]  border-[#d9d9d9] p-2 w-full "
              >
                {parentStates.leftBtnText}
              </button>
            )}
            {parentStates.rightBtnText && (
              <button
                onClick={() => {
                  if (parentStates.funOnRightBtn) {
                    parentStates.funOnRightBtn();
                  }
                  handleClose();
                }}
                className="text-[1rem] cursor-pointer font-[500]  text-[#2136d4] border-[#d9d9d9] p-2 w-full"
              >
                {parentStates.rightBtnText}
              </button>
            )}
          </div>
        )}
      </motion.div>
    </div>
  );
};
