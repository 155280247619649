import axios from "axios";

const BaseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("ACCESS_TOKEN");

const get_admins_by_query_obj = async ({
  queryObject,
  skip,
  limit,
  sortingObj,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/adminsApi/get_admins_by_query_obj`,
      {
        queryObject: queryObject || {},
        skip: skip || 0,
        limit: limit || 1000,
        sortingObj: sortingObj || { createdAt: "desc" },
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    return {
      data: { isSuccess: false, isError: true, data: null, message: error },
    };
  }
};

const handle_creating_new_admin = async ({
  userName,
  password,
  mobile,
  role,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/adminsApi/handleCreatingNewAdmin`,
      {
        userName,
        password,
        mobile,
        role,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log(error);
    return { isSuccess: false, data: null };
  }
};

const update_admin_by_query_obj = async ({
  queryObject,
  updateObject,
  dataValues,
  tags,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/adminsApi/update_admin_by_query_obj`,
      { queryObject, updateObject, dataValues, tags },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
    return {
      isSuccess: false,
    };
  }
};

const DeleteAdminAndProperties = async (id) => {
  try {
    const res = await axios.get(`${BaseUrl}/api/adminsApi/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  } catch (e) {
    return { isSuccess: false, message: e.message };
  }
};

export {
  get_admins_by_query_obj,
  update_admin_by_query_obj,
  handle_creating_new_admin,
  DeleteAdminAndProperties,
};
