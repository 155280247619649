import { useContext, useEffect, useState } from "react";
import StoreContext from "../context/StoreContext";
import { v2Api } from "../api/v2.api";
import AdminContext from "../context/AdminContext";

const useFetchInitialData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { setStoreStates } = useContext(StoreContext);
  const { setAdminState } = useContext(AdminContext);

  useEffect(() => {
    if (window.location.pathname !== "/signin") {
      callMethods();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  async function callMethods() {
    setIsLoading(true);

    // getting the token from the local storage
    const LC_TOKEN =
      localStorage?.getItem("ACCESS_TOKEN") ||
      window.location.search.split("?t=")[1];

    if (!LC_TOKEN) {
      window.location.href = "/signin";
      return;
    }

    const initialAdminData = await v2Api({
      operation: "adminController",
      tags: ["admin"],
      token: LC_TOKEN,
    });

    // the admin is not authenticated
    if (initialAdminData.status === 401) {
      localStorage.clear();
      window.location.href = "/signin";
      return;
    }

    // if the admin data is not fetched successfully
    if (!initialAdminData.isSuccess) {
      window.showMsg("Error while fetching the admin pannel data");
      return;
    }

    // setting up the admin information in the state
    setAdminState((p) => ({
      ...p,
      admin: LC_TOKEN,
      adminInfo: initialAdminData?.admin,
    }));

    const allHubs = [];
    const hubCityPairs = {};
    for (const currCity of initialAdminData?.cityWiseHubs) {
      for (const hub of currCity.hubs) {
        hubCityPairs[hub._id] = currCity.city;
      }
      allHubs.push(...currCity.hubs);
    }

    const decorCategoryList = initialAdminData?.categoriesList.find(
      (category) => category._id === "decorCategory"
    )?.miscData[0]?.info;

    const addonCategoryList = initialAdminData?.categoriesList.find(
      (category) => category._id === "addonCategory"
    )?.miscData[0]?.info;

    setStoreStates((p) => ({
      ...p,
      hubsByCity: initialAdminData?.cityWiseHubs,
      hubs: allHubs,
      allHubs: allHubs,
      defaultHub: allHubs[0],
      hubCityPairs: hubCityPairs,
      decorCategoryList,
      addonCategoryList,
      admins: initialAdminData?.admins,
    }));

    setIsLoading(false);
  }

  return { isLoading };
};

export default useFetchInitialData;
