import { createContext, useContext, useState } from "react";

const StoreContext = createContext();

export const useStoreContext = () => useContext(StoreContext);

export const StoreProvider = ({ children }) => {
  // ---------------------- states --------------------
  const [orders, setOrders] = useState({
    isLoading: false,
    orders: [],
    setOrders: 0,
  });

  const [storeStates, setStoreStates] = useState({
    defaultHub: null,
    hubs: [],
    defaultLocation: null,
    navHeadline: {
      heading: "",
      subHeading: "",
      component: null,
    },
    universalFilter: {
      city: "all",
      hub: "all",
    },
    hubsByCity: [],
    defaultDecorDiscount: {
      isEnabledDiscount: false,
      isEnableDiscountEditModal: false,
      discountValue: 15,
      maxOfferAmountGain: 1500,
      minOrderValue: 3499,
      isRange2: true,
      range2MinOrderValue: 1499,
      range2MaxOrderValue: 3499,
      range2DiscountValue: 10,
    },
    isGoogleMapsScriptLoaded: false,
    decorCategoryList: [],
    addonCategoryList: [],
    allHubs: [],
    hubCityPairs: {}, // {hubId: city, ....}
    admins: [], // will contain all the admin list
  });
  //this will be used as storing and using some data at different routes temporarily
  const [tempData, setTempData] = useState({});

  // -------------------- constants --------------------
  const BaseUrl = process.env.REACT_APP_API_URL;

  return (
    <StoreContext.Provider
      value={{
        BaseUrl,
        orders,
        setOrders,
        storeStates,
        setStoreStates,
        tempData,
        setTempData,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
