import React, { useEffect, useRef } from "react";

const loadScript = (src, id, callback) => {
  if (window.mapkit) {
    callback();
    return;
  }

  const existingScript = document.getElementById(id);

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = src;
    script.id = id;
    script.onload = callback;
    document.body.appendChild(script);
  } else {
    existingScript.onload = callback;
  }
};

const AppleMaps = ({ points, focusPoint }) => {
  // focusPoint is the point to focus on whenever we get a valid point as focusPoint
  // focusPoint : { lat, lng }

  const mapRef = useRef(null);
  const mapInstance = useRef(null); // Store the map instance
  const annotationsRef = useRef([]); // Store the annotations
  const mapInitialized = useRef(false); // To track if the map is initialized

  useEffect(() => {
    const token = process.env.REACT_APP_APPLE_MAPKIT_TOKEN;
    if (!token) {
      console.error("Apple MapKit JS token is missing.");
      return;
    }

    const initMap = () => {
      if (!mapInitialized.current) {
        window.mapkit.init({
          authorizationCallback: (done) => {
            done(token);
          },
        });

        mapInstance.current = new window.mapkit.Map(mapRef.current);
        mapInstance.current.region = new window.mapkit.CoordinateRegion(
          new window.mapkit.Coordinate(
            points[0]?.lat || 0,
            points[0]?.lng || 0
          ),
          new window.mapkit.CoordinateSpan(0.5, 0.5)
        );

        // Add initial annotations
        updateAnnotations(points);

        mapInitialized.current = true; // Mark the map as initialized
      } else {
        // Update annotations if the map is already initialized
        updateAnnotations(points);
      }
    };

    const updateAnnotations = (points) => {
      if (mapInstance.current) {
        // Remove existing annotations
        mapInstance.current.removeAnnotations(annotationsRef.current);

        // Clear the references
        annotationsRef.current = [];

        // Add new annotations
        const newAnnotations = points.map((point) => {
          const coordinate = new window.mapkit.Coordinate(point.lat, point.lng);
          const annotation = new window.mapkit.MarkerAnnotation(coordinate, {
            title: "",
            glyphText: "e",
            color: "green",
          });

          // Check if the point is the focus point
          if (
            focusPoint &&
            point.lat === focusPoint.lat &&
            point.lng === focusPoint.lng
          ) {
            // Apply styles for the focused annotation
            annotation.color = "#2136d4"; // Highlight color
            annotation.selected = true; // Set as selected
          }
          // Add click event listener
          annotation.addEventListener("select", (e) => {
            console.log("Annotation clicked", e);
          });
          annotationsRef.current.push(annotation);
          return annotation;
        });

        mapInstance.current.addAnnotations(newAnnotations);

        // Optionally, update the map's region to center around the new points
        if (focusPoint) {
          mapInstance.current.region = new window.mapkit.CoordinateRegion(
            new window.mapkit.Coordinate(focusPoint.lat, focusPoint.lng),
            new window.mapkit.CoordinateSpan(0.1, 0.1) // Smaller span for closer zoom
          );
        } else if (points.length > 0) {
          mapInstance.current.region = new window.mapkit.CoordinateRegion(
            new window.mapkit.Coordinate(points[0].lat, points[0].lng),
            new window.mapkit.CoordinateSpan(0.5, 0.5)
          );
        }
      }
    };

    loadScript(
      "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js",
      "mapkit-js",
      initMap
    );

    return () => {
      // Only remove the script if it was added by this component
      const script = document.getElementById("mapkit-js");
      if (script && !window.mapkit) {
        document.body.removeChild(script);
      }
    };
  }, [points, focusPoint]);

  return <div ref={mapRef} style={{ width: "100%", height: "500px" }} />;
};

export { AppleMaps };
