import React, { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import StoreContext from "../context/StoreContext";

export default function DecorOfferDiscountSetModal() {
  const { setStoreStates, storeStates } = useContext(StoreContext);

  const handleClose = () => {
    setStoreStates((p) => ({
      ...p,
      defaultDecorDiscount: {
        ...p.defaultDecorDiscount,
        isEnableDiscountEditModal: false,
      },
    }));
  };
  return (
    <AnimatePresence>
      {storeStates.defaultDecorDiscount.isEnableDiscountEditModal && (
        <Modal
          handleClose={handleClose}
          storeStates={storeStates}
          setStoreStates={setStoreStates}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ handleClose, setStoreStates, storeStates }) => {
  const [states, setStates] = useState({
    discountValue: 10,
    maxOfferAmountGain: 500,
    minOrderValue: 1499,
    range2MinOrderValue: 3499,
    range2MaxOrderValue: 1000 * 1000,
    range2DiscountValue: 15,
    range2MaxPriceGain: 100000,
  });

  useEffect(() => {
    setStates({ ...storeStates.defaultDecorDiscount });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    if (!states.discountValue) {
      window.toast("Discount value is required");
      return;
    }
    if (!states.minOrderValue) {
      window.toast("Min order value is required");
      return;
    }
    if (!states.maxOfferAmountGain) {
      window.toast("Max discount amount is required");
      return;
    }
    setStoreStates((p) => ({
      ...p,
      defaultDecorDiscount: {
        ...p.defaultDecorDiscount,
        isEnableDiscountEditModal: false,
        isEnabledDiscount: true,
        discountValue: parseInt(states.discountValue),
        maxOfferAmountGain: parseInt(states.maxOfferAmountGain),
        minOrderValue: parseInt(states.minOrderValue),
        isRange2: Boolean(states.range2DiscountValue),
        range2MinOrderValue: parseInt(states.range2MinOrderValue),
        range2MaxOrderValue: parseInt(states.range2MaxOrderValue) || 0,
        range2DiscountValue: parseInt(states.range2DiscountValue),
        range2MaxPriceGain: parseInt(states.range2MaxPriceGain) || 100000,
      },
    }));
  };

  return (
    <div className="fixed top-0 z-[999] flex items-end justify-end right-0 left-0 bottom-0  ">
      <div
        onClick={handleClose}
        className="fixed top-0 z-[-1] flex items-center justify-center right-0 left-0 bottom-0 bg-[#0000003e]"
      ></div>
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className="flex items-center justify-center w-full flex-col bg-[white] rounded-t-xl   "
      >
        <div className="flex flex-col w-full p-4 text-[.875rem]">
          <div className="w-full flex flex-col">
            <div className=" mb-1 text-gray-500 px-1">Discount (%)</div>
            <input
              value={states.discountValue}
              onChange={(e) =>
                setStates((p) => ({ ...p, discountValue: e.target.value }))
              }
              type="number"
              inputMode="numeric"
              className="p-2 w-full border border-gray-200 rounded-md "
              placeholder="Enter discount value (%)"
            />
          </div>
          <div className=" w-full flex mt-2 flex-nowrap gap-2 whitespace-nowrap overflow-scroll  text-[12px] text-[black]">
            {discountAddArrray.map((curr, idx) => {
              return (
                <div
                  onClick={() =>
                    setStates((p) => ({
                      ...p,
                      discountValue: curr,
                    }))
                  }
                  key={idx}
                  className="flex rounded-lg px-2 py-[2px] items-center border border-gray-300 bg-gray-50   "
                >
                  {curr}%
                </div>
              );
            })}
          </div>
          <div className="w-full flex gap-2 mt-3">
            <div className="w-full flex flex-col">
              <div className=" mb-1 text-gray-500 px-1">From (₹)</div>
              <input
                value={states.minOrderValue}
                onChange={(e) =>
                  setStates((p) => ({ ...p, minOrderValue: e.target.value }))
                }
                type="number"
                inputMode="numeric"
                className="p-2 w-full border border-gray-200 rounded-md "
                placeholder="Min decor price"
              />
            </div>
            <div className="w-full flex flex-col">
              <div className=" mb-1 text-gray-500 px-1">To (₹)</div>
              <input
                value={"Infinity"}
                disabled={true}
                type="text"
                inputMode="numeric"
                className="p-2 w-full border border-gray-200 rounded-md "
                placeholder="max decor price"
              />
            </div>
          </div>
          <div className="w-full flex flex-col mt-3">
            <div className=" mb-1 text-gray-500 px-1">Max discount (₹)</div>
            <input
              value={states.maxOfferAmountGain}
              onChange={(e) =>
                setStates((p) => ({ ...p, maxOfferAmountGain: e.target.value }))
              }
              type="number"
              inputMode="numeric"
              className="p-2 w-full border border-gray-200 rounded-md "
              placeholder="Max Discount gain (%)"
            />
          </div>
          <div className=" w-full flex mt-2 flex-nowrap gap-2 whitespace-nowrap overflow-scroll  text-[12px] text-[black]">
            {amountAddArrray.map((curr, idx) => {
              return (
                <div
                  onClick={() =>
                    setStates((p) => ({
                      ...p,
                      maxOfferAmountGain:
                        curr + (parseInt(states.maxOfferAmountGain) || 0),
                    }))
                  }
                  key={idx}
                  className="flex rounded-lg px-2 py-[2px] items-center border border-gray-300 bg-gray-50   "
                >
                  +{curr}
                </div>
              );
            })}
          </div>
        </div>
        <div className=" mt-1 pt-2 border-t border-gray-200 w-full px-4 text-gray-500 ">
          Range 2 (optional)
        </div>
        <div className="flex flex-col w-full p-4 text-[.875rem]">
          <div className="w-full flex flex-col">
            <div className=" mb-1 text-gray-500 px-1">Discount (%)</div>
            <input
              value={states.range2DiscountValue}
              onChange={(e) =>
                setStates((p) => ({
                  ...p,
                  range2DiscountValue: e.target.value,
                }))
              }
              type="number"
              inputMode="numeric"
              className="p-2 w-full border border-gray-200 rounded-md "
              placeholder="Enter discount value (%)"
            />
            <div className=" w-full flex mt-2 flex-nowrap gap-2 whitespace-nowrap overflow-scroll  text-[12px] text-[black]">
              {discountAddArrray.map((curr, idx) => {
                return (
                  <div
                    onClick={() =>
                      setStates((p) => ({
                        ...p,
                        range2DiscountValue: curr,
                      }))
                    }
                    key={idx}
                    className="flex rounded-lg px-2 py-[2px] items-center border border-gray-300 bg-gray-50   "
                  >
                    {curr}%
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full flex gap-2 mt-3">
            <div className="w-full flex flex-col">
              <div className=" mb-1 text-gray-500 px-1">From (₹)</div>
              <input
                value={states.range2MinOrderValue}
                onChange={(e) =>
                  setStates((p) => ({
                    ...p,
                    range2MinOrderValue: e.target.value,
                  }))
                }
                type="number"
                inputMode="numeric"
                className="p-2 w-full border border-gray-200 rounded-md "
                placeholder="Min decor price"
              />
            </div>
            <div className="w-full flex flex-col">
              <div className=" mb-1 text-gray-500 px-1">To (₹)</div>
              <input
                value={states.range2MaxOrderValue}
                onChange={(e) =>
                  setStates((p) => ({
                    ...p,
                    range2MaxOrderValue: e.target.value,
                  }))
                }
                type="text"
                inputMode="numeric"
                className="p-2 w-full border border-gray-200 rounded-md "
                placeholder="max decor price"
              />
            </div>
          </div>
          {/* <div className="w-full flex flex-col mt-3">
            <div className=" mb-1 text-gray-500 px-1">Max discount (₹)</div>
            <input
              value={states.range2MaxPriceGain}
              onChange={(e) =>
                setStates((p) => ({ ...p, range2MaxPriceGain: e.target.value }))
              }
              type="number"
              inputMode="numeric"
              className="p-2 w-full border border-gray-200 rounded-md "
              placeholder="Max Discount gain (%)"
            /> <div className=" w-full flex mt-2 flex-nowrap gap-2 whitespace-nowrap overflow-scroll  text-[12px] text-[black]">
            {amountAddArrray.map((curr, idx) => {
              return (
                <div
                  onClick={() =>
                    setStates((p) => ({
                      ...p,
                      range2MaxPriceGain:
                        curr + (parseInt(states.range2MaxPriceGain) || 0),
                    }))
                  }
                  key={idx}
                  className="flex rounded-lg px-2 py-[2px] items-center border border-gray-300 bg-gray-50   "
                >
                  +{curr}
                </div>
              );
            })}
          </div>
          </div> */}
        </div>

        <div className="flex  w-full border-t gap-2">
          <button
            onClick={() => {
              handleClose();
            }}
            className="text-[1rem] border-r cursor-pointer font-[500] py-4 border-[#d9d9d9] p-2 w-full "
          >
            Cancel
          </button>

          <button
            onClick={() => {
              handleSubmit();
            }}
            className="text-[1rem] cursor-pointer font-[500] py-4  text-[#2136d4] border-[#d9d9d9] p-2 w-full"
          >
            Done
          </button>
        </div>
      </motion.div>
    </div>
  );
};
const discountAddArrray = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
const amountAddArrray = [100, 200, 500, 1000];
