import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross1 } from "react-icons/rx";
import { IoMdCall } from "react-icons/io";

export default function CallingModal({ parentStates, setParentStates }) {
  const handleClose = async () => {
    setParentStates((p) => ({
      ...p,
      callsModal: {
        isRender: false,
      },
    }));
  };

  return (
    <AnimatePresence>
      {parentStates.callsModal.isRender && (
        <Modal
          handleClose={handleClose}
          options={parentStates.callsModal.options}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ handleClose, options }) => {
  return (
    <div className="fixed top-0 z-[98] flex items-end justify-end md:justify-start right-0 left-0 bottom-0  ">
      <div
        onClick={handleClose}
        className="fixed top-0 z-[-1] flex items-center justify-center right-0 left-0 bottom-0 bg-[#0000003e]"
      ></div>
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: "0%", opacity: 1 }}
        exit={{ y: "100%", opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className="flex items-center justify-center w-full md:w-[30rem]  flex-col bg-[white] rounded-lg   "
      >
        <div className=" w-full flex items-center justify-between p-4 border-b border-gray-200 pb-2 mb-2 ">
          <div className="flex flex-col ">
            <p className="text-[1rem] font-[500] ">
              {options.heading || "Making a call"}
            </p>
          </div>
          <div onClick={handleClose} className="cursor-pointer ">
            <RxCross1 />
          </div>
        </div>
        <div className="w-full px-4">Initiating a call</div>
        <div className=" w-full flex justify-between my-4 px-4">
          <div className="flex flex-col items-center cursor-pointer ">
            <div className=" rounded-full border border-blue-200 mb-1 bg-blue-50 p-2">
              <IoMdCall className="text-[#2136d4] text-[1.25rem]" />
            </div>
            <div className="text-[.75rem] text-[gray] ">You</div>
            <div className="text-[.75rem] text-[gray] ">
              ({options.callerNumber})
            </div>
          </div>
          <div className="h-[2px] w-[80%] relative top-[1.2rem] bg-[#2136d4]  "></div>
          <div className="flex flex-col items-center cursor-pointer ">
            <div className=" rounded-full border border-blue-200  bg-blue-50 p-2">
              <IoMdCall className="text-[#2136d4] text-[1.25rem]" />
            </div>
            <div className="text-[.75rem] text-[gray] ">
              ({options.receiverType})
            </div>
            <div className="text-[.75rem] text-[gray] ">
              ({options.receiverNumber})
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
