import React from "react";
import { AnimatePresence, motion } from "framer-motion";

export default function FloatingCustomModal({ parentStates, setParentStates }) {
  const handleClose = async () => {
    if (parentStates.FloatingCustomModal.options?.onHandleClose) {
      await parentStates.FloatingCustomModal.options?.onHandleClose();
    }
    setParentStates((p) => ({
      ...p,
      FloatingCustomModal: {
        isRender: false,
        component: null,
        UIOptions: {},
        options: {},
      },
    }));
  };

  return (
    <AnimatePresence>
      {parentStates.FloatingCustomModal.isRender && (
        <Modal
          handleClose={handleClose}
          UIOptions={parentStates.FloatingCustomModal.UIOptions}
          component={parentStates.FloatingCustomModal.component}
          options={parentStates.FloatingCustomModal.options}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ handleClose, UIOptions, component, options }) => {
  /* 

  UIOptions.animationLength: in rem
  
  UIOptions.position:
  t : top,
  b : bottom,
  l : left,
  r : right.
  tl : top-left,
  tr : top-right,
  bl : bottom-left,
  br : bottom-right.
  c : center

  */

  const className = {
    parent: {
      t: "justify-center items-start",
      b: "justify-center items-end",
      l: "justify-start items-center",
      r: "justify-end items-center",
      tl: "justify-start items-start",
      tr: "justify-end items-start",
      bl: "justify-start items-end",
      br: "justify-end items-end",
      c: "justify-center items-center",
    },
  };

  const animationLength = UIOptions.animationLength || "100%";

  const componentAnimation = {
    "t-b": {
      initial: { y: "-" + animationLength },
      animate: { y: 0 },
      exit: { y: "-" + animationLength },
    },
    "b-t": {
      initial: { y: animationLength },
      animate: { y: 0 },
      exit: { y: animationLength },
    },
    "l-r": {
      initial: { x: "-" + animationLength },
      animate: { x: 0 },
      exit: { x: "-" + animationLength },
    },
    "r-l": {
      initial: { x: animationLength },
      animate: { x: 0 },
      exit: { x: animationLength },
    },
    "tl-br": {
      initial: { x: "-" + animationLength, y: "-" + animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: "-" + animationLength, y: "-" + animationLength },
    },
    "tr-bl": {
      initial: { x: animationLength, y: "-" + animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: animationLength, y: "-" + animationLength },
    },
    "bl-tr": {
      initial: { x: "-" + animationLength, y: animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: "-" + animationLength, y: animationLength },
    },
    "br-tl": {
      initial: { x: animationLength, y: animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: animationLength, y: animationLength },
    },
    "t-br": {
      initial: { y: "-" + animationLength, x: 0 },
      animate: { y: 0, x: animationLength },
      exit: { y: "-" + animationLength, x: 0 },
    },
    "t-bl": {
      initial: { y: "-" + animationLength, x: 0 },
      animate: { y: 0, x: "-" + animationLength },
      exit: { y: "-" + animationLength, x: 0 },
    },
    "b-tr": {
      initial: { y: animationLength, x: 0 },
      animate: { y: 0, x: animationLength },
      exit: { y: animationLength, x: 0 },
    },
    "b-tl": {
      initial: { y: animationLength, x: 0 },
      animate: { y: 0, x: "-" + animationLength },
      exit: { y: animationLength, x: 0 },
    },
    "l-t": {
      initial: { x: "-" + animationLength, y: 0 },
      animate: { x: 0, y: "-" + animationLength },
      exit: { x: "-" + animationLength, y: 0 },
    },
    "l-b": {
      initial: { x: "-" + animationLength, y: 0 },
      animate: { x: 0, y: animationLength },
      exit: { x: "-" + animationLength, y: 0 },
    },
    "r-t": {
      initial: { x: animationLength, y: 0 },
      animate: { x: 0, y: "-" + animationLength },
      exit: { x: animationLength, y: 0 },
    },
    "r-b": {
      initial: { x: animationLength, y: 0 },
      animate: { x: 0, y: animationLength },
      exit: { x: animationLength, y: 0 },
    },
    "tl-r": {
      initial: { x: "-" + animationLength, y: "-" + animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: "-" + animationLength, y: "-" + animationLength },
    },
    "tl-l": {
      initial: { x: "-" + animationLength, y: "-" + animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: "-" + animationLength, y: "-" + animationLength },
    },
    "tr-r": {
      initial: { x: animationLength, y: "-" + animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: animationLength, y: "-" + animationLength },
    },
    "tr-l": {
      initial: { x: animationLength, y: "-" + animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: animationLength, y: "-" + animationLength },
    },
    "bl-r": {
      initial: { x: "-" + animationLength, y: animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: "-" + animationLength, y: animationLength },
    },
    "bl-l": {
      initial: { x: "-" + animationLength, y: animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: "-" + animationLength, y: animationLength },
    },
    "br-r": {
      initial: { x: animationLength, y: animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: animationLength, y: animationLength },
    },
    "br-l": {
      initial: { x: animationLength, y: animationLength },
      animate: { x: 0, y: 0 },
      exit: { x: animationLength, y: animationLength },
    },
  };

  return (
    <div
      className={`fixed ${
        className.parent[UIOptions?.position || "m"]
      } top-0 z-[15] flex right-0 left-0 bottom-0 `}
    >
      <div
        onClick={handleClose}
        className="fixed top-0 z-[-1] flex items-center justify-center right-0 left-0 bottom-0 bg-[#0000003e]"
      ></div>
      <motion.div
        initial={componentAnimation[UIOptions?.animation || "m"]?.initial}
        animate={componentAnimation[UIOptions?.animation || "m"]?.animate}
        exit={componentAnimation[UIOptions?.animation || "m"]?.exit}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className=""
      >
        {component}
      </motion.div>
    </div>
  );
};
