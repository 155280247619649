import { memo, useCallback, useState } from "react";
import {
  AiOutlineBarChart,
  AiOutlineExpand,
  AiOutlineLineChart,
  AiOutlinePieChart,
} from "react-icons/ai";
import { BsFillEmojiDizzyFill } from "react-icons/bs";
import { PieChart1 } from "./PieChart";
import { LineChart1 } from "./LineChart";
import { BarChart1, StackedBarChart } from "./BarChart";
import { PrimaryBtn } from "../Button";
import { UPDATE_ONE_ANALYTICS_DATA } from "../../utils/constants";

/**
 * @param {types, datas, title} param0
 * @returns { default as ChartComponent }
 */
const ShowChart = memo(
  ({
    id,
    currElement,
    parentState,
    parentDispatch,
    handleFetchAnalyticsData,
  }) => {

    const { 
      categories,
      graphTypes: types,
      dataType,
      graphTitle: title,
    } = currElement;

    const data = parentState.analyticsData[dataType];

    const [pageState, setPageState] = useState({
      type: types?.at(0) || null,
      activeCategoryIdx: 0,
      isLoading: false,
    });

    function handleExpandBtnClick({ type, data, title }) {
      window.viewChartData({
        type,
        data,
        title,
      });
    }

    const handleSelectActiveCategory = useCallback(
      async (ind) => {
        if (ind === pageState.activeCategoryIdx) return;

        setPageState((p) => ({
          ...p,
          activeCategoryIdx: ind,
          isLoading: true,
        }));

        await handleFetchAnalyticsData({
          filterOptions: {
            [currElement.dataType]: categories
              ?.at(ind)
              ?.barSplitConditions({ group_by: parentState.type }),
          },
          onSuccess: (data) => {
            parentDispatch({
              type: UPDATE_ONE_ANALYTICS_DATA,
              payload: { data },
              analyticsDataKey: "delivery",
            });
          },
          onError: (err) => {
            console.log(err);
            // parentDispatch({
            //   type: FETCH_FAILED,
            //   payload: {
            //     isError: true,
            //     errorMessage: err?.message,
            //   },
            // });
          },
          isInitailDataFetch: false,
        });

        setPageState((p) => ({ ...p, isLoading: false }));
      },
      // eslint-disable-next-line
      [pageState.activeCategoryIdx]
    );
    

    if (!pageState.type) {
      return (
        <div key={id} className="flex flex-col items-center justify-center">
          {"Type is required..."}
          <BsFillEmojiDizzyFill className="text-5xl" />
        </div>
      );
    }

    return (
      <div
        key={id}
        className="p-4 h-full w-full bg-white rounded-xl flex items-center justify-center flex-col shadow-lg"
      >
        {/* all navs */}
        <div className="flex justify-end w-full items-center gap-2">
          {types?.includes("line") && (
            <AiOutlineLineChart
              className={`cursor-pointer text-xl p-[1px] rounded-sm ${
                pageState.type === "line" ? "bg-slate-300" : ""
              }`}
              onClick={() => setPageState((p) => ({ ...p, type: "line" }))}
            />
          )}
          {types?.includes("stacked-line") && (
            <AiOutlineLineChart
              className={`cursor-pointer text-xl p-[1px] rounded-sm ${
                pageState.type === "stacked-line" ? "bg-slate-300" : ""
              }`}
              onClick={() =>
                setPageState((p) => ({ ...p, type: "stacked-line" }))
              }
            />
          )}
          {types?.includes("bar") && (
            <AiOutlineBarChart
              className={`cursor-pointer text-xl p-[1px] rounded-sm ${
                pageState.type === "bar" ? "bg-slate-300" : ""
              }`}
              onClick={() => setPageState((p) => ({ ...p, type: "bar" }))}
            />
          )}
          {types?.includes("stacked-bar") && (
            <AiOutlineBarChart
              className={`cursor-pointer text-xl p-[1px] rounded-sm ${
                pageState.type === "stacked-bar" ? "bg-slate-300" : ""
              }`}
              onClick={() =>
                setPageState((p) => ({ ...p, type: "stacked-bar" }))
              }
            />
          )}
          {types?.includes("pie") && (
            <AiOutlinePieChart
              className={`cursor-pointer text-xl p-[1px] rounded-sm ${
                pageState.type === "pie" ? "bg-slate-300" : ""
              }`}
              onClick={() => setPageState((p) => ({ ...p, type: "pie" }))}
            />
          )}
          <AiOutlineExpand
            onClick={() => {
              handleExpandBtnClick({
                type: types?.at(types.indexOf(pageState.type)),
                data: currElement.categories
                  ?.at(pageState.activeCategoryIdx)
                  ?.formatData({ data, type: parentState?.type }),
                title: title,
              });
            }}
          />
        </div>

        {/* all charts and buttons */}
        <div
          className={` overflow-hidden ${
            pageState.type === "pie"
              ? "flex flex-col items-center"
              : "flex flex-col lg:grid lg:grid-cols-[78%_20%] justify-between"
          } mt-4 w-full overflow-hidden`}
        >
          {pageState.isLoading ? (
            <div className="h-[9.5rem] md:h-[14.5rem] aspect-[2/1] flex justify-center items-center">
              <div className="loader w-[1rem]"></div>
            </div>
          ) : (
            // all charts
            <div
              className={`${
                pageState.type === "pie"
                  ? "aspect-square max-h-[20rem]"
                  : "md:aspect-[2/1] w-full"
              } `}
            >
              {pageState.type === "pie" && types?.includes("pie") && (
                <PieChart1
                  // data={datas?.at(types.indexOf("pie"))}
                  data={currElement.categories?.at(0)?.formatData({ data })}
                  title={title}
                />
              )}
              {pageState.type === "line" && types?.includes("line") && (
                <LineChart1
                  // data={datas?.at(types.indexOf("line"))}
                  data={currElement.categories
                    ?.at(pageState.activeCategoryIdx)
                    ?.formatData({ data, type: parentState?.type })}
                  title={title}
                />
              )}
              {pageState.type === "stacked-line" &&
                types?.includes("stacked-line") && (
                  <LineChart1
                    data={currElement.categories
                      ?.at(pageState.activeCategoryIdx)
                      ?.formatData({ data, type: parentState?.type })}
                    // data={datas?.at(types.indexOf("stacked-line"))}
                    title={title}
                  />
                )}
              {pageState.type === "bar" && types?.includes("bar") && (
                <BarChart1
                  // data={datas?.at(types.indexOf("bar"))}
                  data={currElement.categories
                    ?.at(pageState.activeCategoryIdx)
                    ?.formatData({ data, type: parentState?.type })}
                  title={title}
                />
              )}
              {pageState.type === "stacked-bar" &&
                types?.includes("stacked-bar") && (
                  <StackedBarChart
                    data={currElement.categories
                      ?.at(pageState.activeCategoryIdx)
                      ?.formatData({ data, type: parentState?.type })}
                    // data={datas?.at(types.indexOf("stacked-bar"))}
                    title={title}
                  />
                )}
            </div>
          )}
          {pageState.type !== "pie" && (
            <hr className={`my-2 bg-gray-50 md:hidden`} />
          )}

          {/* all buttons */}
          <div
            className={`${
              pageState.type === "pie" ? "hidden" : "flex"
            }   lg:flex-col items-center overflow-x-auto lg:overflow-y-auto  gap-2 border border-gray-200 rounded-lg p-1 md:p-4 w-full `}
          >
            {categories?.length > 0 &&
              categories?.map(({ title }, ind) => (
                <div key={ind}>
                  <PrimaryBtn
                    id={ind}
                    title={title}
                    isActive={pageState.activeCategoryIdx === ind}
                    handleOnClick={() => handleSelectActiveCategory(ind)}
                    className={``}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
);

export default memo(ShowChart);
