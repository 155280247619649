import { memo } from "react";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
);

export const LineChart1 = memo(({ data, title }) => { 
  const options = {
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            if (Number.isInteger(value)) {
              if(value === 0) {
                return value;
              }
              if (Number.isInteger(value / 1000000)) {
                return value/1000000 + 'M';
              } else if (Number.isInteger(value / 1000)) {
                return value / 1000 + 'K';
              } else {
                return value;
              }
            }
            return null;
          },
        },
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
        },
      },
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
      responsive: true,
    },
  };

  return <Line data={data} options={options} />;
})

export const StackedLineChart = memo(({ data, title }) => {

  return (
    <div></div>
  )
})
