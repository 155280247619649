import React, { memo } from "react";
import EboRoutes from "./EboRoutes";

import { BrowserRouter as Router } from "react-router-dom";
import Modals from "./Modals";
import TopNavHeadlines from "../assets/navbarRelated/TopNavHeadlines";
import useFetchInitialData from "../hooks/useFetchInitialData";

const EboRouter = () => {
  const { isLoading } = useFetchInitialData();

  if (isLoading) {
    return (
      <div className="flex text-black items-center flex-col justify-center fixed top-0 right-0 left-0 bottom-0">
        <div className="text-[1.5rem] font-[500] ">Welcome admin</div>
        <div className="text-[1rem] text-[grey] leading-4 ">
          Making all things ready
        </div>
        <div className="loader flex items-center justify-center mt-6 w-[2rem]">
          <div className="loader w-[1rem]"></div>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <div className=" relative text-[black] z-[2]">
        <Modals />
      </div>

      <div className=" relative text-[black] z-[1]">
        <TopNavHeadlines />
        <EboRoutes />
      </div>
    </Router>
  );
};

export default memo(EboRouter);
